import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import { Helmet } from "react-helmet";

const Received = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "right",
          button: "account", // cta, account, null
        }}
      >
      <Helmet>
        <title>Application Submitted - Ethical Hacker Indonesia</title>  
        <meta name="description" content="Data yang kamu kirimkan sudah kami terima. Silakan ditunggu untuk konfirmasi selanjutnya." />
        <meta name="image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
        <meta property="og:url" content="https://hack.co.id/received/" />
        <meta property="og:title" content="Application Submitted" />
        <meta property="og:description" content="Data yang kamu kirimkan sudah kami terima. Silakan ditunggu untuk konfirmasi selanjutnya." />
        <meta property="og:image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@EthicalHackID" />
        <meta name="twitter:title" content="Application Submitted"  />
        <meta name="twitter:description" content="Data yang kamu kirimkan sudah kami terima. Silakan ditunggu untuk konfirmasi selanjutnya." />
        <meta name="twitter:image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
      </Helmet>  
        <div className="inner-banner pt-29 pb-6">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9" md="11">
                <div className="px-xl-15 px-lg-8 text-center">
                  <h2 className="title gr-text-2 mb-9">
                    Terima kasih
                  </h2>
                  <p className="gr-text-8 mb-13">
                    Data yang kamu kirimkan sudah kami terima. Silakan ditunggu untuk konfirmasi selanjutnya.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        
      </PageWrapper>
    </>
  );
};
export default Received;
